*,
*:before,
*:after {
  box-sizing: inherit; }

.__encantos {
  box-sizing: border-box;
  color: #424450;
  font-family: 'Inter', sans-serif;
  font-size: 16px; }
  .__encantos * {
    margin: 0;
    padding: 0; }
  .__encantos a {
    outline: none;
    text-decoration: none; }
  .__encantos button {
    border: none;
    cursor: pointer;
    font-size: inherit;
    outline: none; }
    .__encantos button[disabled] {
      cursor: default;
      opacity: 0.500; }
  .__encantos input {
    border: none;
    font-size: inherit;
    outline: none; }
    .__encantos input::placeholder {
      opacity: 1.000; }
  .__encantos select {
    border: none;
    font-size: inherit;
    outline: none; }
  .__encantos textarea {
    border: none;
    outline: none;
    resize: none; }

body {
  margin: 0;
  padding: 0; }
  body.no-scroll {
    overflow: hidden; }

.common__select {
  position: relative; }
  .common__select .common__select__options {
    position: relative; }
    .common__select .common__select__options::after {
      background: url("../assets/select.svg");
      content: '';
      height: 8px;
      pointer-events: none;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      z-index: 1; }
    .common__select .common__select__options label {
      color: #313d76;
      display: block;
      font-size: 12px;
      left: 25px;
      line-height: 1;
      pointer-events: none;
      position: absolute;
      text-transform: uppercase;
      top: 12px; }
    .common__select .common__select__options select {
      appearance: none;
      -webkit-appearance: none;
      border-radius: 10px;
      border: solid 1px #cccccc;
      color: #00c4d1;
      height: 56px;
      padding: 20px 20px 6px 20px;
      width: 100%; }
      .common__select .common__select__options select option {
        color: #000000; }
      .common__select .common__select__options select optgroup {
        color: #000000;
        font-style: normal; }
  .common__select .common__select__arrow {
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
    .common__select .common__select__arrow img {
      height: 8px;
      width: 15px; }

.common__container {
  margin: 0 auto;
  position: relative;
  width: 1130px; }

.common__banner {
  background: #00c4d1; }
  .common__banner .common__container p {
    align-items: center;
    color: #ffffff;
    font-size: 18px;
    padding: 10px 20px;
    text-align: center; }

.common__header .common__container {
  padding: 57px 0 85px 0; }
  .common__header .common__container .common__header__row__1 {
    display: flex;
    height: 50px; }
    .common__header .common__container .common__header__row__1 .common__header__brand {
      display: flex;
      width: 100%; }
      .common__header .common__container .common__header__row__1 .common__header__brand img {
        display: block;
        height: 50px;
        width: 227px; }
    .common__header .common__container .common__header__row__1 .common__header__navigation ul {
      display: flex;
      list-style: none; }
      .common__header .common__container .common__header__row__1 .common__header__navigation ul li {
        align-items: center;
        display: flex;
        height: 50px;
        line-height: 1;
        position: relative; }
        .common__header .common__container .common__header__row__1 .common__header__navigation ul li a {
          color: #313d76;
          white-space: nowrap; }
        .common__header .common__container .common__header__row__1 .common__header__navigation ul li button {
          background: none; }
        .common__header .common__container .common__header__row__1 .common__header__navigation ul li + li {
          margin: 0 0 0 37px; }
        .common__header .common__container .common__header__row__1 .common__header__navigation ul li.selected a {
          color: #4a5baf; }
          .common__header .common__container .common__header__row__1 .common__header__navigation ul li.selected a:after {
            background: #4a5baf;
            border-radius: 3px;
            bottom: 0;
            content: '';
            height: 5px;
            left: 1px;
            position: absolute;
            width: 30px; }
  .common__header .common__container .common__header__row__2 {
    position: absolute;
    top: 26px;
    right: 0; }
    .common__header .common__container .common__header__row__2 .common__header__account ul {
      display: flex;
      list-style: none; }
      .common__header .common__container .common__header__row__2 .common__header__account ul li {
        align-items: center;
        color: #4a5baf;
        display: flex;
        font-size: 12px;
        line-height: 1; }
        .common__header .common__container .common__header__row__2 .common__header__account ul li a {
          color: #4a5baf; }
        .common__header .common__container .common__header__row__2 .common__header__account ul li img {
          margin: 0 10px 0 0; }
        .common__header .common__container .common__header__row__2 .common__header__account ul li span {
          margin: 0 3px; }
        .common__header .common__container .common__header__row__2 .common__header__account ul li + li {
          margin: 0 0 0 30px; }

.common__header__mobile__menu {
  display: none; }

.collection__hero .common__container {
  display: flex;
  height: 167px;
  flex-direction: column;
  justify-content: center; }
  .collection__frontpage .collection__hero .common__container, .collection__all .collection__hero .common__container, .is-search .collection__hero .common__container {
    height: 335px; }
  .collection__hero .common__container .collection__hero__title {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 72px;
    line-height: 72px;
    position: relative;
    z-index: 1; }
    .collection__hero .common__container .collection__hero__title strong {
      font-weight: 800; }
  .collection__hero .common__container .collection__hero__subtitle {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 72px;
    line-height: 72px;
    position: relative;
    z-index: 1; }
  .collection__hero .common__container .collection__hero__button {
    margin: 20px 0 0 0;
    position: relative;
    z-index: 1; }
    .collection__hero .common__container .collection__hero__button a {
      align-items: center;
      background-color: #00c4d1;
      border-radius: 32px;
      color: #ffffff;
      display: flex;
      font-family: "Poppins", sans-serif;
      font-weight: 800;
      justify-content: center;
      height: 56px;
      width: 260px; }
  .collection__hero .common__container .collection__hero__image {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 1440px;
    z-index: 0; }
    .collection__hero .common__container .collection__hero__image img {
      display: block;
      height: 100%;
      width: 100%; }
    .collection__hero .common__container .collection__hero__image .collection__hero__mask {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0));
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }

.common__plp .common__container {
  display: flex;
  padding: 100px 0 0 0;
  justify-content: space-between; }
  .common__plp .common__container .common__plp__aside {
    width: 264px; }
    .common__plp .common__container .common__plp__aside .common__plp__sidebar {
      border-top: 1px solid #cccccc; }
      .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group {
        border-bottom: 1px dashed #cccccc; }
        .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__primary a {
          align-items: center;
          color: #313d76;
          display: flex;
          font-family: "Poppins", sans-serif;
          font-weight: bold;
          font-size: 24px;
          height: 64px;
          justify-content: space-between; }
          .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__primary a .common__plp__primary__indicator.plus {
            display: block; }
          .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__primary a .common__plp__primary__indicator.minus {
            display: none; }
        .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__primary.selected a {
          color: #4a5baf; }
          .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__primary.selected a .common__plp__primary__indicator.plus {
            display: none; }
          .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__primary.selected a .common__plp__primary__indicator.minus {
            display: block; }
        .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__secondary {
          display: none;
          padding: 10px 0 30px 0; }
          .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__secondary.selected {
            display: block; }
          .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__secondary ul {
            list-style: none; }
            .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__secondary ul li a {
              color: #4a5baf; }
            .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__secondary ul li + li {
              margin: 30px 0 0 0; }
            .common__plp .common__container .common__plp__aside .common__plp__sidebar .common__plp__group .common__plp__secondary ul li.selected a {
              color: #00c4d1;
              font-weight: bold; }
    .common__plp .common__container .common__plp__aside .common__plp__misc__2 {
      margin: 50px 0 0 0; }
      .common__plp .common__container .common__plp__aside .common__plp__misc__2 img {
        height: 45px;
        width: 45px; }
    .common__plp .common__container .common__plp__aside .common__plp__sidebar__image {
      margin: 45px 0 0 0; }
      .common__plp .common__container .common__plp__aside .common__plp__sidebar__image img {
        height: auto;
        width: 100%; }
  .common__plp .common__container .common__plp__main {
    overflow: hidden;
    width: 839px; }
    .common__plp .common__container .common__plp__main .common__plp__misc__3 {
      position: absolute;
      right: 0;
      top: 42px; }
      .common__plp .common__container .common__plp__main .common__plp__misc__3 img {
        height: 29px;
        width: 29px; }
    .common__plp .common__container .common__plp__main .common__plp__search {
      border-bottom: 1px solid #4a5baf;
      padding: 0 0 10px 0;
      position: relative; }
      .common__plp .common__container .common__plp__main .common__plp__search input {
        border: none;
        color: #cccccc;
        display: block;
        font-size: 24px;
        height: 30px;
        width: 100%; }
        .common__plp .common__container .common__plp__main .common__plp__search input::placeholder {
          color: #cccccc; }
      .common__plp .common__container .common__plp__main .common__plp__search button {
        background: none;
        height: 30px;
        position: absolute;
        right: 0;
        top: 0;
        width: 30px; }
    .common__plp .common__container .common__plp__main .common__plp__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 56px;
      margin: 29px 0 44px 0; }
      .common__plp .common__container .common__plp__main .common__plp__header .common__plp__header__title {
        align-items: baseline;
        display: flex; }
        .common__plp .common__container .common__plp__main .common__plp__header .common__plp__header__title h1 {
          color: #4a5baf;
          font-family: "Poppins", sans-serif;
          font-size: 32px;
          font-weight: bold;
          height: 45px;
          margin: 0 17px 0 0; }
      .common__plp .common__container .common__plp__main .common__plp__header .common__plp__header__sort {
        width: 289px; }
    .common__plp .common__container .common__plp__main .common__plp__products {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: -22px -11px; }
      .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product {
        padding: 22px 11px;
        width: 33.333%; }
        .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__image {
          align-items: center;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          overflow: hidden;
          position: relative; }
          .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__image img {
            display: block;
            max-height: 100%;
            max-width: 100%; }
        .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__details {
          margin: 26px 0 0 0; }
          .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__details ul {
            list-style: none; }
            .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__details ul li {
              line-height: 24px; }
              .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__details ul li a {
                color: #313d76;
                font-weight: bold; }
              .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__details ul li em {
                color: #00c4d1;
                font-style: normal; }
        .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up {
          width: 66.666%; }
          .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            padding: 30px;
            position: relative;
            width: 100%; }
            .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__background {
              border-radius: 20px;
              height: 100%;
              left: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              width: 100%;
              z-index: -1; }
              .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__background img {
                display: block;
                height: 100%;
                object-fit: cover;
                width: 100%; }
            .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__title {
              color: #ffffff;
              font-family: "Poppins", sans-serif;
              font-size: 40px;
              font-weight: 800;
              line-height: 1;
              padding: 30px 0; }
            .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__cta__items {
              display: flex; }
              .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__cta__items .__2up__cta__item a {
                display: block;
                height: 56px; }
                .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__cta__items .__2up__cta__item a.__label {
                  align-items: center;
                  background-color: #00c4d1;
                  border-radius: 32px;
                  color: #ffffff;
                  display: flex;
                  font-weight: 800;
                  justify-content: center;
                  padding: 0 30px; }
                .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__cta__items .__2up__cta__item a.__image img {
                  height: 100%;
                  width: auto; }
              .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__cta__items .__2up__cta__item + .__2up__cta__item {
                margin: 0 0 0 20px; }
      .common__plp .common__container .common__plp__main .common__plp__products .common__plp__empty {
        padding: 22px 11px; }
    .common__plp .common__container .common__plp__main .common__plp__pagination {
      align-items: center;
      display: flex;
      justify-content: end;
      margin: 44px 0 0 0; }
      .common__plp .common__container .common__plp__main .common__plp__pagination span {
        margin: 0 20px; }

.common__pdp .common__container {
  display: flex;
  justify-content: space-between; }
  .common__pdp .common__container .common__pdp__header .common__pdp__back a {
    color: #4a5baf;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    height: 36px; }
    .common__pdp .common__container .common__pdp__header .common__pdp__back a strong {
      font-weight: bold; }
  .common__pdp .common__container .common__pdp__gallery {
    width: 553px; }
    .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__featured {
      align-items: center;
      border-radius: 20px;
      display: flex;
      height: 553px;
      justify-content: center;
      margin: 42px 0 0 0;
      overflow: hidden;
      position: relative;
      width: 553px; }
      .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__featured img {
        left: 0;
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        top: 0; }
    .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel {
      display: flex;
      justify-content: center;
      margin: 50px 0 0 0; }
      .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel * {
        outline: none; }
      .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .common__pdp__gallery__carousel__item {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 10px; }
        .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .common__pdp__gallery__carousel__item .common__pdp__gallery__carousel__image {
          align-items: center;
          border-radius: 10px;
          display: flex;
          height: 150px;
          justify-content: center;
          overflow: hidden;
          width: 150px; }
          .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .common__pdp__gallery__carousel__item .common__pdp__gallery__carousel__image img {
            cursor: pointer;
            max-height: 100%;
            max-width: 100%; }
      .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .slick-arrow {
        background: none;
        background-size: 12px 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        text-indent: -999999px;
        transform: translateY(-50%);
        width: 12px;
        z-index: 2; }
        .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .slick-arrow.slick-prev {
          background-image: url(../assets/left.svg);
          left: -39px; }
        .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .slick-arrow.slick-next {
          background-image: url(../assets/right.svg);
          right: -39px; }
  .common__pdp .common__container .common__pdp__details {
    width: 457px; }
    .common__pdp .common__container .common__pdp__details .common__pdp__details__row__1 .common__pdp__details__misc__3 {
      align-items: center;
      display: flex;
      height: 36px; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__1 .common__pdp__details__misc__3 img {
        height: 29px;
        width: 29px; }
    .common__pdp .common__container .common__pdp__details .common__pdp__details__row__2 {
      margin: 42px 0 0 0; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__2 .common__pdp__details__title h1 {
        color: #4a5baf;
        font-family: "Poppins", sans-serif;
        font-size: 48px;
        font-weight: bold;
        line-height: 56px; }
    .common__pdp .common__container .common__pdp__details .common__pdp__details__row__3 {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 13px 0 0 0; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__3 .common__pdp__details__price {
        color: #00c4d1;
        font-size: 32px; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__3 .common__pdp__details__reviews .spr-badge .spr-badge-starrating .spr-icon-star,
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__3 .common__pdp__details__reviews .spr-badge .spr-badge-starrating .spr-icon-star-half-alt {
        color: #ffd300; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__3 .common__pdp__details__reviews .spr-badge .spr-badge-caption {
        color: #313d76;
        margin: 0 0 0 11px; }
    .common__pdp .common__container .common__pdp__details .common__pdp__details__row__4 {
      margin: 13px 0 0 0; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__4 .common__pdp__details__vendor {
        font-size: 12px;
        text-transform: uppercase; }
    .common__pdp .common__container .common__pdp__details .common__pdp__details__row__5 {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 29px 0 0 0; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__5 .common__pdp__details__options {
        display: flex; }
        .common__pdp .common__container .common__pdp__details .common__pdp__details__row__5 .common__pdp__details__options select {
          padding-right: 44px; }
        .common__pdp .common__container .common__pdp__details .common__pdp__details__row__5 .common__pdp__details__options .common__pdp__details__variant {
          margin: 0 22px 0 0;
          min-width: 132px; }
        .common__pdp .common__container .common__pdp__details .common__pdp__details__row__5 .common__pdp__details__options .common__pdp__details__quantity {
          min-width: 132px; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__5 .common__pdp__details__misc__2 img {
        height: 45px;
        width: 45px; }
    .common__pdp .common__container .common__pdp__details .common__pdp__details__row__6 {
      margin: 30px 0 0 0; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__6 .common__pdp__details__cart button {
        align-items: center;
        background-color: #00c4d1;
        border-radius: 32px;
        color: #ffffff;
        display: flex;
        font-weight: 800;
        height: 56px;
        justify-content: center;
        width: 260px; }
    .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 {
      margin: 13px 0 0 0; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group {
        border-bottom: #cccccc dashed 1px; }
        .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__primary {
          align-items: center;
          color: #313d76;
          cursor: pointer;
          display: flex;
          font-family: "Poppins", sans-serif;
          font-size: 24px;
          font-weight: bold;
          height: 65px;
          justify-content: space-between; }
          .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__primary .common__pdp__details__about__indicator.plus {
            display: block; }
          .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__primary .common__pdp__details__about__indicator.minus {
            display: none; }
          .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__primary.selected .common__pdp__details__about__indicator.plus {
            display: none; }
          .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__primary.selected .common__pdp__details__about__indicator.minus {
            display: block; }
        .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__secondary {
          color: #313d76;
          display: none;
          padding: 0 0 26px 0; }
          .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__secondary.selected {
            display: block; }
          .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__secondary p:not(:first-child) {
            margin: 20px 0 0 0; }
          .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__secondary ul:not(:first-child), .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__secondary ol:not(:first-child) {
            margin: 20px 0 0 0; }
          .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__secondary ul li, .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__secondary ol li {
            margin: 0 0 0 18px; }
  .common__pdp .common__container .common__pdp__reviews {
    margin: 100px 0 0 0;
    opacity: 0;
    width: 100%; }
    .common__pdp .common__container .common__pdp__reviews.loaded {
      opacity: 1; }
    .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews {
      margin: 0;
      overflow: visible; }
      .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container {
        border: none;
        margin: 0;
        padding: 0; }
        .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-header .spr-header-title {
          color: #313d76;
          font-family: "Poppins", sans-serif;
          font-size: 24px;
          line-height: 1;
          margin: 0; }
        .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-header .spr-summary {
          margin: 22px 0 0 0; }
          .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-header .spr-summary .spr-summary-starrating .spr-icon-star,
          .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-header .spr-summary .spr-summary-starrating .spr-icon-star-half-alt {
            color: #ffd300; }
          .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-header .spr-summary .spr-summary-actions a {
            color: #00c4d1; }
        .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content {
          margin: 11px -11px -11px -11px; }
          .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form {
            border: #cccccc dashed;
            border-width: 1px 0;
            margin: 22px 11px 11px 11px;
            padding: 22px 0; }
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-title {
              display: none; }
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-label {
              font-size: 12px;
              text-transform: uppercase; }
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-input-text,
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-input-email {
              appearance: none;
              -webkit-appearance: none;
              border-radius: 10px;
              border: solid 1px #cccccc;
              color: inherit;
              font-family: inherit;
              font-size: inherit;
              height: 56px;
              padding: 20px;
              width: 100%; }
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-input-text::placeholder,
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-input-email::placeholder {
                opacity: 0.500; }
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-starrating .spr-icon-star {
              color: #ffd300; }
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-starrating .spr-icon-star.spr-icon-star-empty {
                color: inherit; }
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-starrating .spr-icon-star.spr-icon-star-hover {
                color: #ffd300; }
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-input-textarea {
              appearance: none;
              -webkit-appearance: none;
              border-radius: 10px;
              border: solid 1px #cccccc;
              color: inherit;
              font-family: inherit;
              font-size: inherit;
              padding: 20px;
              width: 100%; }
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-input-textarea::placeholder {
                opacity: 0.500; }
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-actions {
              display: flex;
              justify-content: center; }
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-form .spr-form-actions .spr-button {
                align-items: center;
                background-color: #00c4d1;
                border-radius: 32px;
                color: #ffffff;
                display: flex;
                font-weight: 800;
                height: 56px;
                justify-content: center;
                width: 260px; }
          .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews {
            margin: 0;
            padding: 0;
            width: 100%; }
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper {
              outline: none;
              padding: 11px; }
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper .spr-review {
                background: #e5f7fa;
                border: none;
                border-radius: 20px;
                margin: 0;
                padding: 44px; }
                .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper .spr-review .spr-review-header .spr-review-header-starratings {
                  margin: 0; }
                  .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper .spr-review .spr-review-header .spr-review-header-starratings .spr-icon-star {
                    color: #ffd300; }
                .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper .spr-review .spr-review-header .spr-review-header-title {
                  font-size: 20px;
                  margin: 11px 0 0 0; }
                .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper .spr-review .spr-review-header .spr-review-header-byline {
                  margin: 11px 0 0 0; }
                .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper .spr-review .spr-review-content {
                  margin: 11px 0 0 0;
                  padding: 0; }
                  .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper .spr-review .spr-review-content .spr-review-content-body {
                    font-size: 20px;
                    line-height: 1.5; }
                .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper .spr-review .spr-review-footer {
                  display: none; }
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .slick-list {
              width: 100%; }
            .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .slick-arrow {
              background: none;
              border: none;
              height: 25px;
              position: absolute;
              text-indent: -999999px;
              top: 50%;
              transform: translateY(-50%);
              width: 13px; }
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .slick-arrow.slick-prev {
                background-image: url(../assets/left.svg);
                left: -35px; }
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .slick-arrow.slick-next {
                background-image: url(../assets/right.svg);
                right: -35px; }
              .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .slick-arrow.slick-disabled {
                display: none !important; }

.common__plp__related {
  margin: 100px 0 0 0; }
  .common__plp__related .common__container {
    padding: 0; }
    .common__plp__related .common__container .common__plp__main {
      width: 100%; }
      .common__plp__related .common__container .common__plp__main .common__plp__related__title {
        margin: 0 0 22px 0; }
        .common__plp__related .common__container .common__plp__main .common__plp__related__title h2 {
          color: #313d76;
          font-family: "Poppins", sans-serif;
          font-size: 24px;
          line-height: 1;
          margin: 0; }
      .common__plp__related .common__container .common__plp__main .common__plp__products .common__plp__product {
        width: 25%; }

.common__cart .common__container .common__cart__header .common__cart__header__title h1 {
  color: #4a5baf;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: bold; }
.common__cart .common__container .common__cart__products table {
  border-collapse: collapse;
  width: 100%; }
  .common__cart .common__container .common__cart__products table thead tr th {
    border-bottom: #4a5baf solid 1px;
    padding: 20px;
    text-align: left; }
    .common__cart .common__container .common__cart__products table thead tr th:first-child {
      padding-left: 0; }
    .common__cart .common__container .common__cart__products table thead tr th:last-child {
      padding-right: 0; }
  .common__cart .common__container .common__cart__products table tbody tr td {
    border-bottom: #cccccc solid 1px;
    padding: 20px;
    text-align: left;
    vertical-align: top; }
    .common__cart .common__container .common__cart__products table tbody tr td:first-child {
      padding-left: 0; }
    .common__cart .common__container .common__cart__products table tbody tr td:last-child {
      padding-right: 0; }
    .common__cart .common__container .common__cart__products table tbody tr td a {
      color: #313d76; }
    .common__cart .common__container .common__cart__products table tbody tr td button {
      background: none;
      color: #313d76; }
    .common__cart .common__container .common__cart__products table tbody tr td input {
      border: #cccccc solid 1px;
      border-radius: 5px;
      display: block;
      margin: -5px 0;
      padding: 5px 10px;
      width: 60px; }
.common__cart .common__container .common__cart__summary {
  display: flex;
  justify-content: flex-end; }
  .common__cart .common__container .common__cart__summary .common__cart__summary__subtotal {
    color: #4a5baf;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    padding: 20px 0; }
  .common__cart .common__container .common__cart__summary .common__cart__summary__buttons button {
    align-items: center;
    background-color: #00c4d1;
    border-radius: 32px;
    color: #ffffff;
    display: flex;
    font-weight: 800;
    height: 56px;
    justify-content: center;
    width: 100%; }

.common__family .common__container {
  padding: 88px 0; }
  .common__family .common__container p {
    color: #4a5baf;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-align: center; }

.common__footer {
  background: #4a5baf; }
  .common__footer .common__container {
    padding: 125px 0 138px 0; }
    .common__footer .common__container .common__footer__row__1 .common__footer__app p {
      color: #ffffff;
      font-size: 48px;
      line-height: 56px; }
    .common__footer .common__container .common__footer__row__1 .common__footer__app ul {
      list-style: none; }
      .common__footer .common__container .common__footer__row__1 .common__footer__app ul li {
        margin: 20px 0 0 0; }
        .common__footer .common__container .common__footer__row__1 .common__footer__app ul li a {
          align-items: center;
          background: #ffffff;
          border-radius: 39px;
          color: #313d76;
          display: flex;
          font-family: "Poppins", sans-serif;
          font-size: 24px;
          font-weight: 800;
          height: 78px;
          line-height: 1;
          padding: 0 36px;
          width: 263px; }
          .common__footer .common__container .common__footer__row__1 .common__footer__app ul li a img {
            height: 39px;
            margin: 0 12px 0 0; }
          .common__footer .common__container .common__footer__row__1 .common__footer__app ul li a span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            justify-items: center;
            white-space: nowrap; }
            .common__footer .common__container .common__footer__row__1 .common__footer__app ul li a span small {
              font-size: 12px;
              font-weight: normal;
              margin: 0 0 2px 0; }
    .common__footer .common__container .common__footer__row__1 .common__footer__logos ul {
      display: flex;
      list-style: none; }
      .common__footer .common__container .common__footer__row__1 .common__footer__logos ul li {
        align-items: center;
        display: flex;
        height: 55px; }
        .common__footer .common__container .common__footer__row__1 .common__footer__logos ul li img {
          display: block; }
        .common__footer .common__container .common__footer__row__1 .common__footer__logos ul li + li {
          margin: 0 0 0 15px; }
    .common__footer .common__container .common__footer__row__2 {
      display: flex;
      justify-content: space-between;
      margin-top: 26px; }
      .common__footer .common__container .common__footer__row__2 .common__footer__app p {
        color: #ffffff;
        font-size: 48px;
        line-height: 56px; }
      .common__footer .common__container .common__footer__row__2 .common__footer__app ul {
        list-style: none; }
        .common__footer .common__container .common__footer__row__2 .common__footer__app ul li {
          margin: 20px 0 0 0; }
          .common__footer .common__container .common__footer__row__2 .common__footer__app ul li a {
            align-items: center;
            background: #ffffff;
            border-radius: 39px;
            color: #313d76;
            display: flex;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-weight: 800;
            height: 78px;
            line-height: 1;
            padding: 0 36px;
            width: 263px; }
            .common__footer .common__container .common__footer__row__2 .common__footer__app ul li a img {
              height: 39px;
              margin: 0 12px 0 0; }
            .common__footer .common__container .common__footer__row__2 .common__footer__app ul li a span {
              display: flex;
              flex-direction: column;
              justify-content: center;
              justify-items: center;
              white-space: nowrap; }
              .common__footer .common__container .common__footer__row__2 .common__footer__app ul li a span small {
                font-size: 12px;
                font-weight: normal;
                margin: 0 0 2px 0; }
      .common__footer .common__container .common__footer__row__2 .common__footer__newsletter {
        margin: 20px 0 0 0; }
        .common__footer .common__container .common__footer__row__2 .common__footer__newsletter p {
          color: #ffffff;
          font-family: "Poppins", sans-serif;
          font-size: 32px;
          font-weight: 800;
          line-height: 40px;
          width: 673px; }
        .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form {
          display: flex;
          margin: 20px 0 0 0; }
          .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field {
            position: relative; }
            .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field label {
              color: #ffffff;
              left: 0;
              line-height: 56px;
              opacity: 0.500;
              pointer-events: none;
              position: absolute;
              top: 0; }
            .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field input {
              background: none;
              border: none;
              border-bottom: solid 1px #ffffff;
              color: #ffffff;
              height: 56px; }
              .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field input[type="name"] {
                width: 211px; }
              .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field input[type="email"] {
                width: 292px; }
              .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field input.error {
                border-color: #ff0000; }
            .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field + .common__footer__newsletter__field {
              margin: 0 0 0 20px; }
          .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form button {
            align-items: center;
            background: #ffffff;
            border-radius: 32px;
            color: #4a5baf;
            display: flex;
            font-family: "Poppins", sans-serif;
            font-weight: 800;
            height: 56px;
            justify-content: center;
            margin: 0 0 0 30px;
            width: 191px; }
    .common__footer .common__container .common__footer__row__3 {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 50px; }
      .common__footer .common__container .common__footer__row__3 .common__footer__links ul {
        display: flex;
        list-style: none; }
        .common__footer .common__container .common__footer__row__3 .common__footer__links ul li a {
          color: #ffffff; }
        .common__footer .common__container .common__footer__row__3 .common__footer__links ul li + li {
          margin: 0 0 0 36px; }
      .common__footer .common__container .common__footer__row__3 .common__footer__social ul {
        display: flex;
        list-style: none; }
        .common__footer .common__container .common__footer__row__3 .common__footer__social ul li a {
          color: #ffffff;
          display: block;
          height: 32px;
          overflow: hidden;
          width: 32px; }
        .common__footer .common__container .common__footer__row__3 .common__footer__social ul li + li {
          margin: 0 0 0 20px; }
    .common__footer .common__container .common__footer__row__4 {
      align-items: end;
      display: flex;
      justify-content: space-between;
      margin-top: 27px; }
      .common__footer .common__container .common__footer__row__4 .common__footer__brand {
        height: 30px; }
        .common__footer .common__container .common__footer__row__4 .common__footer__brand p a {
          color: #ffffff; }
      .common__footer .common__container .common__footer__row__4 .common__footer__legal p {
        color: #ffffff;
        font-size: 12px;
        opacity: 0.500; }

.page-width * {
  font-family: 'Inter', sans-serif; }
.page-width a {
  color: #4a5baf; }
.page-width h1 {
  color: #4a5baf;
  font-family: "Poppins", sans-serif;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px; }
.page-width h2 {
  color: #4a5baf;
  font-family: "Poppins", sans-serif;
  text-transform: none; }
.page-width .btn {
  align-items: center;
  background-color: #00c4d1;
  border-radius: 32px;
  color: #ffffff;
  display: flex !important;
  font-size: 16px;
  font-weight: 800;
  height: 56px;
  justify-content: center;
  text-transform: none;
  width: 260px !important; }
  .page-width .btn:hover:not([disabled]) {
    background-color: #00c4d1; }
.page-width.text-center .btn,
.page-width .text-center .btn {
  margin: 0 auto; }

@media only screen and (max-width: 767px) {
  .sm-none {
    display: none !important; }

  .collection__hero .common__container {
    height: 135px;
    margin: 0; }
    .collection__frontpage .collection__hero .common__container, .collection__all .collection__hero .common__container, .is-search .collection__hero .common__container {
      height: 135px; }
    .collection__hero .common__container .collection__hero__title {
      font-size: 24px;
      line-height: 24px;
      padding: 0 20px; }
    .collection__hero .common__container .collection__hero__image {
      width: 1164px; }
      .collection__frontpage .collection__hero .common__container .collection__hero__image, .collection__all .collection__hero .common__container .collection__hero__image, .is-search .collection__hero .common__container .collection__hero__image {
        width: 580px; }

  .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up {
    width: 100%; }
    .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper {
      align-items: center;
      padding: 20px; }
      .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__title {
        font-size: 24px;
        padding: 20px 0;
        text-align: center; }
      .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__cta__items {
        align-items: center;
        flex-direction: column; }
        .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product.__2up .__2up__wrapper .__2up__cta__items .__2up__cta__item + .__2up__cta__item {
          margin: 20px 0 0 0; } }
@media only screen and (max-width: 1024px) {
  .common__select .common__select__options label {
    left: 21px; }

  .common__container {
    margin: 0 20px;
    overflow-x: hidden;
    width: auto; }

  .common__header .common__container {
    padding: 17px 0 21px 0; }
    .common__header .common__container .common__header__row__1 {
      height: 36px; }
      .common__header .common__container .common__header__row__1 .common__header__brand img {
        height: 36px;
        width: 163px; }
      .common__header .common__container .common__header__row__1 .common__header__navigation ul li a img {
        height: 24px; }
      .common__header .common__container .common__header__row__1 .common__header__navigation ul li + li {
        margin: 0 0 0 21px; }

  .common__header__mobile__menu {
    background: #f7f9ff;
    height: 100%;
    left: 0;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999; }
    .common__header__mobile__menu .common__header__mobile__menu__brand {
      margin: 45px 0 0 0; }
      .common__header__mobile__menu .common__header__mobile__menu__brand p a img {
        height: 40px;
        width: 184px; }
    .common__header__mobile__menu .common__header__mobile__menu__navigation ul {
      list-style: none;
      margin: 48px 0 0 0; }
      .common__header__mobile__menu .common__header__mobile__menu__navigation ul li a {
        color: #313d76;
        font-size: 20px; }
      .common__header__mobile__menu .common__header__mobile__menu__navigation ul li + li {
        margin: 32px 0 0 0; }
    .common__header__mobile__menu .common__header__mobile__menu__social {
      bottom: 0;
      left: 0;
      padding: 52px 20px;
      position: absolute;
      width: 100%; }
      .common__header__mobile__menu .common__header__mobile__menu__social ul {
        display: flex;
        list-style: none;
        /*         justify-content: space-between; */ }
        .common__header__mobile__menu .common__header__mobile__menu__social ul li a img {
          height: 34px;
          width: 34px; }
    .common__header__mobile__menu .common__header__mobile__menu__close {
      position: absolute;
      top: 20px;
      right: 20px; }
      .common__header__mobile__menu .common__header__mobile__menu__close p button {
        background: none; }
        .common__header__mobile__menu .common__header__mobile__menu__close p button img {
          height: 40px;
          width: 40px; }
    .common__header__mobile__menu::after {
      /*       background-image: url(../assets/bunny.svg);
            content: '';
            height: 320px;
            position: absolute;
            right: 20px;
            top: 279px;
            width: 208px; */ }

  .common__plp .common__container {
    padding: 40px 0 0 0; }
    .common__plp .common__container .common__plp__main {
      width: 100%; }
      .common__plp .common__container .common__plp__main .common__plp__search input {
        font-size: 16px; }
      .common__plp .common__container .common__plp__main .common__plp__header {
        height: auto;
        flex-direction: column-reverse;
        margin: 35px 0 35px 0; }
        .common__plp .common__container .common__plp__main .common__plp__header .common__plp__header__sort {
          display: flex;
          justify-content: space-between;
          margin: -11px;
          padding: 0 9px;
          width: 100vw; }
          .common__plp .common__container .common__plp__main .common__plp__header .common__plp__header__sort .common__select {
            padding: 11px;
            width: 50%; }
        .common__plp .common__container .common__plp__main .common__plp__header .common__plp__header__title {
          margin: 30px 0 0 0;
          width: 100%; }
          .collection__all .common__plp .common__container .common__plp__main .common__plp__header .common__plp__header__title, .is-search .common__plp .common__container .common__plp__main .common__plp__header .common__plp__header__title {
            margin: 0; }
          .common__plp .common__container .common__plp__main .common__plp__header .common__plp__header__title h1 {
            height: auto;
            line-height: 1; }
      .common__plp .common__container .common__plp__main .common__plp__products {
        margin: -10px; }
        .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product {
          padding: 10px;
          width: 50%; }
          .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__details ul li {
            font-size: 14px;
            line-height: 18px; }
            .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__details ul li a {
              font-size: 16px; }
            .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__details ul li em {
              font-size: 16px; }
            .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product .common__plp__product__details ul li + li {
              margin: 5px 0 0 0; }

  .common__pdp .common__container {
    flex-direction: column; }
    .common__pdp .common__container .common__pdp__header .common__pdp__title {
      margin: 15px 0 0 0; }
      .common__pdp .common__container .common__pdp__header .common__pdp__title h1 {
        color: #4a5baf;
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-weight: 800;
        line-height: 1; }
    .common__pdp .common__container .common__pdp__header .common__pdp__price {
      color: #00c4d1;
      font-size: 24px;
      margin: 15px 0 0 0; }
    .common__pdp .common__container .common__pdp__header .common__pdp__vendor {
      font-size: 12px;
      margin: 15px 0 0 0;
      text-transform: uppercase; }
    .common__pdp .common__container .common__pdp__gallery {
      width: auto; }
      .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel {
        margin: 29px 0 0 0; }
        .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .common__pdp__gallery__carousel__item {
          padding: 0; }
          .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .common__pdp__gallery__carousel__item .common__pdp__gallery__carousel__image {
            height: 335px;
            pointer-events: none;
            width: 335px; }
        .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .slick-arrow.slick-prev {
          left: 10px; }
        .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .slick-arrow.slick-next {
          right: 10px; }
    .common__pdp .common__container .common__pdp__details {
      width: auto; }
      .common__pdp .common__container .common__pdp__details .common__pdp__details__row__7 .common__pdp__details__about__group .common__pdp__details__about__primary {
        font-size: 16px; }
    .common__pdp .common__container .common__pdp__reviews {
      margin: 50px 0 0 0; }
      .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews {
        overflow: hidden; }
        .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-header .spr-summary .spr-summary-actions {
          display: block;
          margin: 22px 0 0 0; }
        .common__pdp .common__container .common__pdp__reviews #shopify-product-reviews .spr-container .spr-content .spr-reviews .spr-review-wrapper .spr-review .spr-review-content .spr-review-content-body {
          font-size: 16px; }

  .common__cart .common__container .common__cart__products table thead {
    display: none; }
  .common__cart .common__container .common__cart__products table tbody tr td {
    border-bottom: #cccccc solid 1px;
    display: flex;
    padding-bottom: 20px;
    padding: 20px 0 20px 0; }
    .common__cart .common__container .common__cart__products table tbody tr td img {
      margin: 0 20px 0 0;
      width: 100px; }
    .common__cart .common__container .common__cart__products table tbody tr td ul {
      list-style: none; }
      .common__cart .common__container .common__cart__products table tbody tr td ul li + li {
        align-items: center;
        display: flex;
        margin: 20px 0 0 0; }
        .common__cart .common__container .common__cart__products table tbody tr td ul li + li input {
          margin: 0 10px 0 0; }
  .common__cart .common__container .common__cart__summary {
    justify-content: center; }

  .common__family .common__container {
    padding: 50px 0; }
    .common__family .common__container p {
      font-size: 24px;
      line-height: 32px; }

  .common__footer .common__container {
    padding: 48px 0; }
    .common__footer .common__container .common__footer__row__1 .common__footer__app p {
      font-size: 32px;
      line-height: 1;
      text-align: center; }
    .common__footer .common__container .common__footer__row__1 .common__footer__app ul {
      display: flex;
      justify-content: center; }
      .common__footer .common__container .common__footer__row__1 .common__footer__app ul li {
        padding: 0 10px; }
        .common__footer .common__container .common__footer__row__1 .common__footer__app ul li a {
          font-size: 16px;
          height: 64px;
          padding: 0 15px;
          width: 156px; }
          .common__footer .common__container .common__footer__row__1 .common__footer__app ul li a img {
            height: 27px;
            margin: 0 5px 0 0; }
    .common__footer .common__container .common__footer__row__1 .common__footer__logos {
      margin: 30px 0 0 0; }
      .common__footer .common__container .common__footer__row__1 .common__footer__logos ul {
        flex-wrap: wrap;
        justify-content: center; }
    .common__footer .common__container .common__footer__row__2 {
      flex-direction: column;
      margin: 30px 0 0 0; }
      .common__footer .common__container .common__footer__row__2 .common__footer__newsletter {
        margin: 0;
        text-align: center; }
        .common__footer .common__container .common__footer__row__2 .common__footer__newsletter p {
          font-size: 24px;
          line-height: 32px;
          width: auto; }
        .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form {
          align-items: center;
          flex-direction: column;
          justify-content: center; }
          .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field {
            margin: 0 0 20px 0 !important;
            width: 100%; }
            .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field input {
              max-width: 360px;
              width: 100% !important; }
          .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form button {
            margin: 0; }
    .common__footer .common__container .common__footer__row__3 {
      flex-direction: column-reverse;
      margin: 60px 0 0 0; }
      .common__footer .common__container .common__footer__row__3 .common__footer__links {
        margin: 30px 0 0 0;
        text-align: center; }
        .common__footer .common__container .common__footer__row__3 .common__footer__links ul {
          flex-wrap: wrap;
          justify-content: center;
          margin: -5px -10px; }
          .common__footer .common__container .common__footer__row__3 .common__footer__links ul li {
            display: inline-block;
            padding: 5px 10px; }
            .common__footer .common__container .common__footer__row__3 .common__footer__links ul li + li {
              margin: 0; }
    .common__footer .common__container .common__footer__row__4 {
      display: block;
      margin: 30px 0 0 0;
      text-align: center; }
      .common__footer .common__container .common__footer__row__4 .common__footer__legal {
        margin: 30px 0 0 0; } }
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .md-none {
    display: none !important; }

  .collection__hero .common__container {
    margin: 0; }
    .collection__hero .common__container .collection__hero__title {
      font-size: 48px;
      line-height: 48px;
      padding: 0 36px; }
    .collection__hero .common__container .collection__hero__subtitle {
      font-size: 48px;
      line-height: 48px;
      padding: 0 36px; }
    .collection__hero .common__container .collection__hero__button {
      padding: 0 36px; }

  .common__plp .common__container .common__plp__main .common__plp__products .common__plp__product {
    width: 33.333%; }

  .common__pdp .common__container {
    flex-direction: row; }
    .common__pdp .common__container .common__pdp__header {
      margin: 0 0 30px 0; }
      .common__pdp .common__container .common__pdp__header .common__pdp__title {
        margin: 20px 0; }
        .common__pdp .common__container .common__pdp__header .common__pdp__title h1 {
          font-size: 48px;
          line-height: 56px; }
    .common__pdp .common__container .common__pdp__gallery {
      padding: 0 20px 0 0;
      width: 50%; }
      .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__featured {
        height: 336px;
        margin: 0;
        width: 336px; }
      .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel {
        margin: 30px auto 0 auto;
        width: 265px; }
        .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .common__pdp__gallery__carousel__item .common__pdp__gallery__carousel__image {
          height: 75px;
          pointer-events: auto;
          width: 75px; }
        .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .slick-arrow.slick-prev {
          left: -39px; }
        .common__pdp .common__container .common__pdp__gallery .common__pdp__gallery__carousel .slick-arrow.slick-next {
          right: -39px; }
    .common__pdp .common__container .common__pdp__details {
      width: 50%; } }
@media only screen and (min-width: 1025px) {
  .lg-none {
    display: none !important; } }
/* gift cards */
.gift-card-container {
  display: flex;
  padding: 2rem 0 0 0; }
  .gift-card-container .gift-card-image {
    width: 25%; }
    .gift-card-container .gift-card-image img {
      display: block;
      height: auto;
      width: 100%; }
  .gift-card-container .gift-card-instructions {
    padding: 0 0 0 2rem; }
    .gift-card-container .gift-card-instructions table tr th {
      text-align: right; }
    .gift-card-container .gift-card-instructions table tr td {
      padding: 0 0 0 1rem; }

@media only screen and (max-width: 1024px) {
  .gift-card-container {
    flex-direction: column-reverse; }
    .gift-card-container .gift-card-image {
      margin: 2rem 0 0 0;
      width: auto; } }
/* cus-css */
.common-header-main .hedder-top {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 50px;
  border-bottom: 3px solid #ebebeb; }

.common-header-main .header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px; }

.common__header__navigation ul.main-linklist, .common__header__mobile__menu__social ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap; }

.common__header__navigation ul.main-linklist li {
  margin-right: 30px;
  position: relative;
  padding: 30px 0; }

.common__header__navigation li a {
  position: relative;
  color: #313d76;
  font-family: 'Inter', sans-serif;
  font-size: 16px; }

.common__header__navigation ul.main-linklist li a::after,
ul.main-linklist li a.site-nav__link--active:after {
  content: '';
  position: absolute;
  display: block;
  bottom: -12px;
  height: 5px;
  width: 30px;
  border-radius: 100px;
  background-color: #4a5baf;
  opacity: 0;
  transition: opacity 0.1s ease-in-out; }

ul.main-linklist li a.site-nav__link--active:after {
  opacity: 1; }

.common__header__navigation ul.main-linklist li:hover a::after, .common__header__navigation ul.main-linklist li.active a::after {
  opacity: 1; }

.common__header__navigation ul.sub-linklist li a::after {
  display: none; }

.common__header__account ul {
  list-style: none; }

.common__header__account ul li a {
  color: #313d76;
  font-family: 'Inter', sans-serif;
  font-size: 12px; }

.common__header__account ul li {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.common__header__account ul li img {
  padding-right: 5px; }

.header-icon-right {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.header-cart, .common__header__account {
  margin-left: 10px;
  padding-left: 10px; }

.desktop-social-icon li a img {
  height: 25px;
  width: 25px; }

.desktop-social-icon li {
  padding: 0 5px; }

.common__header__navigation ul.main-linklist li ul.sub-linklist {
  margin-left: 0;
  left: 0;
  position: absolute;
  top: 100%;
  width: 208px;
  z-index: 2;
  padding: 0;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: -1px; }

.common__header__navigation ul.main-linklist li ul.sub-linklist {
  list-style: none;
  padding: 20px;
  width: 300px;
  border-radius: 14px;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease; }

.common__header__navigation ul.main-linklist li ul.sub-linklist::before {
  content: "";
  height: 18px;
  width: 34px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 34px;
  top: -13px;
  border-radius: 150px 150px 0 0;
  background-color: #fff;
  box-shadow: 0px -4px 8px 0 rgba(0, 0, 0, 0.1); }

.common__header__navigation ul.main-linklist li ul.sub-linklist li {
  margin: 0;
  padding: 0;
  margin-bottom: 3px; }

.common__header__navigation ul.main-linklist li ul.sub-linklist li a {
  line-height: 23px;
  padding: 8px 15px;
  display: block;
  transition: all 0.2s ease; }

.common__header__navigation ul.main-linklist li ul.sub-linklist li:hover a {
  border-radius: 100px;
  background: #D5D9E1 !important;
  transition: all 0.2s ease; }

.common__header__navigation ul.main-linklist li:hover ul.sub-linklist {
  opacity: 1;
  visibility: visible;
  transition: all 0.2s ease; }

.hedder-top .top-middle {
  width: 40%;
  justify-content: center; }

.hedder-top .top-left {
  width: 30%; }

.hedder-top > div {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 1px;
  line-height: 1 !important; }

.hedder-top .top-middle .image-btn {
  background: #00C4D4;
  font-weight: 900;
  border-radius: 100px;
  color: #ffffff;
  text-align: center;
  padding: 17px 15px 15px 80px;
  font-size: 18px;
  margin: 20px 0;
  position: relative;
  display: inline-block;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3); }

.hedder-top .top-middle .image-btn img {
  position: absolute;
  top: -15px;
  left: -10px;
  width: 80px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
  border-radius: 18px; }

.hedder-top.top-middle img {
  max-width: 170px; }

.hedder-top .top-right {
  width: 30%;
  justify-content: flex-end; }

.hedder-top .top-right a img {
  max-width: 130px;
  margin-left: 12px; }

.hedder-top .top-right a:first-child {
  margin: 0; }

@media (max-width: 1024px) {
  ul.mobile-menu li.site-nav--has-dropdown ul.sub-linklist {
    margin: 25px 0 0 0; }

  ul.mobile-menu li.site-nav--has-dropdown ul.sub-linklist li {
    margin: 0 0 5px 0; }

  ul.mobile-menu li.site-nav--has-dropdown ul.sub-linklist li a {
    padding: 0 0px 17px 15px;
    display: block;
    border-bottom: 1px solid #f2f2f2; }

  .common-header-main .hedder-top {
    padding: 10px 20px;
    justify-content: space-between; }

  .common__header__mobile__menu__navigation ul.mobile-menu li.site-nav--has-dropdown a {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

  .common__header__mobile__menu .common__header__mobile__menu__navigation ul li a span.icon-fafa {
    font-size: 25px;
    border: 1px solid #313d76;
    padding: 0 5px; }

  .common__header__mobile__menu .common__header__mobile__menu__navigation ul li a i.fa {
    transition: all 0.2s ease; }

  .common__header__mobile__menu .common__header__mobile__menu__navigation ul li a.nav-active i.fa {
    transform: rotate(180deg);
    transition: all 0.2s ease; }

  .common__header__mobile__menu .common__header__mobile__menu__social {
    margin-top: 30px;
    position: relative; }

  .common__header__mobile__menu__social ul li {
    margin-right: 30px; }

  .common__header__mobile__menu__social ul li:last-child {
    margin-right: 0px; }

  .hedder-top .top-middle, .hedder-top .top-right {
    display: none; }

  .common__header__mobile__menu__trigger button {
    height: 3px;
    width: 40px;
    background-color: #7347e7;
    position: relative; }

  .common__header__mobile__menu__trigger button:before {
    content: '';
    position: absolute;
    background-color: #7347e7;
    height: 3px;
    width: 40px;
    top: -11px;
    left: 0; }

  .common__header__mobile__menu__trigger button:after {
    content: '';
    position: absolute;
    background-color: #7347e7;
    height: 3px;
    width: 40px;
    bottom: -11px;
    left: 0; } }
@media (max-width: 370px) {
  .common__header__mobile__menu__social ul li {
    margin-right: 15px; } }
/*=================font-family================  */
@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-Black.eot");
  src: url("OktaNeue-Black.eot?#iefix") format("embedded-opentype"), url("OktaNeue-Black.woff2") format("woff2"), url("OktaNeue-Black.woff") format("woff"), url("OktaNeue-Black.svg#OktaNeue-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-UltraLight.eot");
  src: url("OktaNeue-UltraLight.eot?#iefix") format("embedded-opentype"), url("OktaNeue-UltraLight.woff2") format("woff2"), url("OktaNeue-UltraLight.woff") format("woff"), url("OktaNeue-UltraLight.svg#OktaNeue-UltraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-BlackItalic.eot");
  src: url("OktaNeue-BlackItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-BlackItalic.woff2") format("woff2"), url("OktaNeue-BlackItalic.woff") format("woff"), url("OktaNeue-BlackItalic.svg#OktaNeue-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-ExtraLight.eot");
  src: url("OktaNeue-ExtraLight.eot?#iefix") format("embedded-opentype"), url("OktaNeue-ExtraLight.woff2") format("woff2"), url("OktaNeue-ExtraLight.woff") format("woff"), url("OktaNeue-ExtraLight.svg#OktaNeue-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-BoldItalic.eot");
  src: url("OktaNeue-BoldItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-BoldItalic.woff2") format("woff2"), url("OktaNeue-BoldItalic.woff") format("woff"), url("OktaNeue-BoldItalic.svg#OktaNeue-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-ExtraLightItalic.eot");
  src: url("OktaNeue-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-ExtraLightItalic.woff2") format("woff2"), url("OktaNeue-ExtraLightItalic.woff") format("woff"), url("OktaNeue-ExtraLightItalic.svg#OktaNeue-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-Bold.eot");
  src: url("OktaNeue-Bold.eot?#iefix") format("embedded-opentype"), url("OktaNeue-Bold.woff2") format("woff2"), url("OktaNeue-Bold.woff") format("woff"), url("OktaNeue-Bold.svg#OktaNeue-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-Heavy.eot");
  src: url("OktaNeue-Heavy.eot?#iefix") format("embedded-opentype"), url("OktaNeue-Heavy.woff2") format("woff2"), url("OktaNeue-Heavy.woff") format("woff"), url("OktaNeue-Heavy.svg#OktaNeue-Heavy") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-HeavyItalic.eot");
  src: url("OktaNeue-HeavyItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-HeavyItalic.woff2") format("woff2"), url("OktaNeue-HeavyItalic.woff") format("woff"), url("OktaNeue-HeavyItalic.svg#OktaNeue-HeavyItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-RegularItalic.eot");
  src: url("OktaNeue-RegularItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-RegularItalic.woff2") format("woff2"), url("OktaNeue-RegularItalic.woff") format("woff"), url("OktaNeue-RegularItalic.svg#OktaNeue-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue Normal';
  src: url("OktaNeue-NormalItalic.eot");
  src: url("OktaNeue-NormalItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-NormalItalic.woff2") format("woff2"), url("OktaNeue-NormalItalic.woff") format("woff"), url("OktaNeue-NormalItalic.svg#OktaNeue-NormalItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-LightItalic.eot");
  src: url("OktaNeue-LightItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-LightItalic.woff2") format("woff2"), url("OktaNeue-LightItalic.woff") format("woff"), url("OktaNeue-LightItalic.svg#OktaNeue-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-MediumItalic.eot");
  src: url("OktaNeue-MediumItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-MediumItalic.woff2") format("woff2"), url("OktaNeue-MediumItalic.woff") format("woff"), url("OktaNeue-MediumItalic.svg#OktaNeue-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-Light.eot");
  src: url("OktaNeue-Light.eot?#iefix") format("embedded-opentype"), url("OktaNeue-Light.woff2") format("woff2"), url("OktaNeue-Light.woff") format("woff"), url("OktaNeue-Light.svg#OktaNeue-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-Medium.eot");
  src: url("OktaNeue-Medium.eot?#iefix") format("embedded-opentype"), url("OktaNeue-Medium.woff2") format("woff2"), url("OktaNeue-Medium.woff") format("woff"), url("OktaNeue-Medium.svg#OktaNeue-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue Normal';
  src: url("OktaNeue-Normal.eot");
  src: url("OktaNeue-Normal.eot?#iefix") format("embedded-opentype"), url("OktaNeue-Normal.woff2") format("woff2"), url("OktaNeue-Normal.woff") format("woff"), url("OktaNeue-Normal.svg#OktaNeue-Normal") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-ThinItalic.eot");
  src: url("OktaNeue-ThinItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-ThinItalic.woff2") format("woff2"), url("OktaNeue-ThinItalic.woff") format("woff"), url("OktaNeue-ThinItalic.svg#OktaNeue-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-UltraLightItalic.eot");
  src: url("OktaNeue-UltraLightItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-UltraLightItalic.woff2") format("woff2"), url("OktaNeue-UltraLightItalic.woff") format("woff"), url("OktaNeue-UltraLightItalic.svg#OktaNeue-UltraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-Regular.eot");
  src: url("OktaNeue-Regular.eot?#iefix") format("embedded-opentype"), url("OktaNeue-Regular.woff2") format("woff2"), url("OktaNeue-Regular.woff") format("woff"), url("OktaNeue-Regular.svg#OktaNeue-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-SemiBold.eot");
  src: url("OktaNeue-SemiBold.eot?#iefix") format("embedded-opentype"), url("OktaNeue-SemiBold.woff2") format("woff2"), url("OktaNeue-SemiBold.woff") format("woff"), url("OktaNeue-SemiBold.svg#OktaNeue-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-Thin.eot");
  src: url("OktaNeue-Thin.eot?#iefix") format("embedded-opentype"), url("OktaNeue-Thin.woff2") format("woff2"), url("OktaNeue-Thin.woff") format("woff"), url("OktaNeue-Thin.svg#OktaNeue-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Okta Neue';
  src: url("OktaNeue-SemiBoldItalic.eot");
  src: url("OktaNeue-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("OktaNeue-SemiBoldItalic.woff2") format("woff2"), url("OktaNeue-SemiBoldItalic.woff") format("woff"), url("OktaNeue-SemiBoldItalic.svg#OktaNeue-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

/*=====================  */
.own-custome-header .top-middle .image-btn {
  background: #7347E7 !important;
  padding: 22px 40px;
  border-radius: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: 'Okta Neue'; }

.account-cus-sign a {
  color: #7347e7;
  font-weight: bold;
  border: 2px solid currentColor;
  padding: 20px 40px;
  border-radius: 10px;
  font-size: 18px; }

.cart-add-own a i {
  color: #7347e7;
  font-size: 25px; }

/* .cart-add-own a{
letter-spacing: 39px;
} */
.common__header__navigation li a {
  color: #180C38;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  font-family: 'Okta Neue'; }

.common-header-main .hedder-top {
  border-bottom: 0; }

.own-custome-header .top-left {
  flex: 0 0 60%;
  max-width: 60%; }

.cart-add-own {
  flex: 0 0 8%;
  max-width: 8%;
  /*   justify-content: flex-end; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.own-custome-header .top-middle {
  flex: 0 0 20%;
  max-width: 20%;
  justify-content: flex-end; }

.account-cus-sign {
  flex: 0 0 10%;
  max-width: 10%;
  justify-content: flex-end; }

.own-custome-header .common-header-main .hedder-top {
  justify-content: space-between; }

.own-custome-header .header-icon-right {
  display: block; }

.social-icon-allset li a i {
  color: #7347e7;
  font-size: 20px; }

.social-icon-allset {
  justify-content: space-between; }

.social-icon-allset li {
  border: 2px solid #7347e7;
  border-radius: 60px;
  padding: 7px 9px;
  margin: 0 5px; }

.fb-icon-pad {
  padding: 7px 12px !important; }

.pin-pad-all-set {
  padding: 7px 11px !important; }

.account-cus-sign a:hover {
  background: #7347E7 !important;
  color: #fff; }

.site-nav--has-dropdown i {
  font-size: 13px; }

.common__footer {
  background: #180B38; }

.footer-all-set {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px; }

.scroll-top-sec a {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  font-family: 'Okta Neue'; }

.footer-icon {
  display: flex;
  list-style: none;
  align-items: center; }

.custm-own-footer .common__footer__newsletter {
  margin: 0px !important;
  flex: 0 0 50%;
  max-width: 50%; }

.custm-own-footer .common__footer__newsletter form {
  margin: 0px !important;
  align-items: center;
  justify-content: space-between; }

.common__footer .common__container .common__footer__row__2 {
  align-items: center !important; }

.follow-social {
  flex: 0 0 35%;
  max-width: 35%; }

.mail-input-design {
  border: 1px solid #fff !important;
  border-radius: 10px;
  width: 452px !important;
  padding: 0px 22px; }

.span-same-foot span {
  font-size: 27px;
  color: #fff;
  font-family: 'Okta Neue'; }

.custm-own-footer .common__footer__newsletter__field label {
  padding: 0px 22px; }

.footer-icon li {
  background: #fff;
  margin: 0 0px; }

.common__footer .common__container .common__footer__row__3 .common__footer__links ul {
  flex-wrap: wrap; }

.linklist-footer-set li {
  flex: 0 0 14.28%;
  max-width: 14.28%;
  display: flex;
  justify-content: flex-start; }

.common__footer .common__container .common__footer__row__3 .common__footer__links ul li + li {
  margin: 0px !important; }

li#footer-main-7 {
  justify-content: flex-end; }

.linklist-footer-set a {
  font-size: 21px;
  text-transform: capitalize;
  line-height: 50px;
  font-family: 'Okta Neue'; }

.footer-page-width {
  margin: 0 auto;
  position: relative;
  max-width: 1400px;
  padding: 125px 0 120px 0 !important;
  width: 100%; }

.common__footer .common__container .common__footer__row__4 {
  align-items: center; }

.common__footer .common__container .common__footer__row__4 .common__footer__legal p {
  color: #fff;
  font-size: 16px;
  opacity: 1;
  font-family: 'Okta Neue'; }

.anker-mailto {
  color: #fff;
  font-size: 16px; }

.copy-right2 p {
  color: #fff;
  font-size: 16px;
  opacity: 1;
  font-family: 'Okta Neue'; }

.common__footer .common__container .common__footer__row__3 {
  margin: 50px 0px !important; }

.common__footer .common__container .common__footer__row__4 {
  margin-top: 58px; }

.foot-odd-set {
  flex: 0 0 35%;
  max-width: 35%; }

.header-logo-set img {
  max-width: 281px; }

.header-logo-set a {
  display: flex; }

.mail-input-design::placeholder {
  font-weight: 600;
  font-size: 18px; }

.cart-add-own img {
  width: 30px; }

@media (max-width: 1537px) {
  .account-cus-sign {
    flex: 0 0 15%;
    max-width: 15%; }

  .own-custome-header .top-left {
    flex: 0 0 50%;
    max-width: 50%; } }
@media (max-width: 1499px) {
  .own-custome-header .top-middle {
    flex: 0 0 25%;
    max-width: 25%; } }
@media (max-width: 1440px) {
  .mail-input-design {
    width: 341px !important; }

  .span-same-foot span {
    font-size: 22px; }

  .linklist-footer-set a {
    font-size: 17px; }

  .footer-page-width {
    max-width: unset;
    padding: 125px 50px !important;
    width: unset; } }
@media (max-width: 1200px) {
  .account-cus-sign a {
    padding: 18px 36px; }

  .own-custome-header .top-middle .image-btn {
    padding: 20px 18px; }

  .header-logo-set img {
    max-width: 255px; } }
@media (max-width: 1024px) {
  .common__header__mobile__menu .common__header__mobile__menu__navigation ul li a {
    color: #180c38;
    font-family: 'Okta Neue'; }

  .common__header__mobile__menu__social .mob-social li {
    margin-right: 0px; }

  .mobile-menu-burger {
    max-width: 32%; }

  .common__header__mobile__menu .common__header__mobile__menu__social {
    padding: 0px 0px; }

  .header-logo-set img {
    max-width: 209px; }

  .common__footer .common__container .common__footer__row__2 {
    flex-direction: unset;
    flex-wrap: wrap; }

  .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form {
    flex-direction: unset;
    max-width: 57%;
    padding-top: 31px; }

  .follow-social {
    flex: 0 0 100%;
    max-width: 100%; }

  .custm-own-footer .common__footer__newsletter {
    flex: 0 0 100%;
    max-width: 100%; }

  .touch-foot-add {
    flex: 0 0 35%;
    max-width: 35%;
    display: flex;
    justify-content: flex-start; }

  .footer-icon {
    max-width: 50%; }

  .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field {
    margin: 0 0 0px 0 !important; }

  .account-cus-sign {
    flex: 0 0 15%;
    max-width: 15%; }

  .common__header__mobile__menu__trigger {
    flex: 0 0 5%;
    max-width: 5%;
    justify-content: flex-start; }

  .cart-add-own {
    flex: 0 0 10%;
    max-width: 10%;
    justify-content: inherit; }

  .own-custome-header .top-left {
    order: 1; }

  .cart-add-own {
    order: 2; }

  .account-cus-sign {
    order: 2; }

  .common__footer .common__container .common__footer__row__3 {
    flex-direction: unset; }

  .common__footer .common__container .common__footer__row__3 .common__footer__links ul {
    justify-content: flex-start; }

  .common__footer .common__container .common__footer__row__3 .common__footer__links {
    text-align: unset; }

  .linklist-footer-set li {
    flex: 0 0 33.33%;
    max-width: 33.33%; }

  .footer-page-width {
    padding: 71px 20px !important; }

  .linklist-footer-set a {
    line-height: 35px; } }
@media (max-width: 991px) {
  .account-cus-sign {
    flex: 0 0 25%;
    max-width: 25%; }

  .own-custome-header .top-left {
    flex: 0 0 40%;
    max-width: 40%; }

  .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form {
    max-width: 70%; }

  .footer-icon {
    max-width: 66%; } }
@media (max-width: 767px) {
  .mobile-menu-burger {
    max-width: 42%; }

  .header-logo-set img {
    max-width: 172px; }

  .cart-add-own {
    flex: 0 0 15%;
    max-width: 15%; }

  .own-custome-header .top-left {
    flex: 0 0 35%;
    max-width: 35%; }

  .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form {
    max-width: 80%; }

  .footer-icon {
    max-width: 73%; }

  .common__footer .common__container .common__footer__row__3 {
    margin: 28px 0 !important; } }
@media (max-width: 749px) {
  .mobile-menu-burger {
    max-width: 47%; }

  .account-cus-sign a {
    padding: 13px 32px; } }
@media (max-width: 568px) {
  .account-cus-sign a {
    padding: 11px 20px; }

  .header-logo-set img {
    max-width: 166px; }

  .mobile-menu-burger {
    max-width: 58%; }

  .account-cus-sign a {
    font-size: 15px; }

  .header-logo-set img {
    max-width: 152px; }

  .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field input {
    height: 47px; }

  .span-same-foot span {
    font-size: 18px; }

  .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form {
    max-width: 100%; }

  .footer-icon {
    max-width: 89%; }

  .linklist-footer-set li {
    flex: 0 0 50%;
    max-width: 50%; }

  .cart-add-own img {
    width: 24px; } }
@media (max-width: 480px) {
  .mobile-menu-burger {
    max-width: 74%; }

  .account-cus-sign a {
    font-size: 12px;
    padding: 10px 15px; }

  .cart-add-own a i {
    font-size: 20px; }

  .account-cus-sign {
    flex: 0 0 20%;
    max-width: 20%; }

  .linklist-footer-set a {
    line-height: 27px; }

  .foot-odd-set {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: center; }

  .footer-icon {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%; }

  .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form {
    flex-wrap: wrap; }

  .touch-foot-add {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: center; }

  .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px !important; }

  .footer-icon li {
    margin: 5px; }

  .footer-page-width {
    padding: 43px 20px !important; }

  .common__footer .common__container .common__footer__row__3 .common__footer__links {
    text-align: center; }

  .common__footer .common__container .common__footer__row__3 {
    margin: 11px 0 !important; }

  .footer-all-set {
    margin-bottom: 33px; } }
@media (max-width: 380px) {
  .mobile-menu-burger {
    max-width: 85%; }

  .account-cus-sign a {
    font-size: 12px;
    padding: 6px 4px; }

  .header-logo-set img {
    max-width: 121px; }

  .common__header__mobile__menu__trigger button:before {
    width: 25px; }

  .common__header__mobile__menu__trigger button:after {
    width: 25px; }

  .footer-icon {
    max-width: 100%; }

  .span-same-foot span {
    font-size: 15px; }

  .linklist-footer-set a {
    font-size: 15px; }

  .common__footer .common__container .common__footer__row__4 .common__footer__legal p {
    font-size: 12px; }

  .anker-mailto {
    font-size: 12px; }

  .copy-right2 p {
    font-size: 12px; }

  .mail-input-design {
    padding: 0 6px; }

  .mail-input-design::placeholder {
    font-size: 12px; }

  .scroll-top-sec a {
    font-size: 15px; } }
@media (max-width: 320px) {
  .cart-add-own a i {
    font-size: 17px; }

  .header-logo-set img {
    max-width: 102px; }

  .common__footer .common__container .common__footer__row__2 .common__footer__newsletter form .common__footer__newsletter__field input {
    height: 38px; }

  .linklist-footer-set li {
    flex: 0 0 100%;
    max-width: 100%; }

  .cart-add-own img {
    width: 18px; } }
